import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SocialBlock from '~/components/blocks/SocialBlock'
import MainLayout from '~/components/layouts/MainLayout'
import CompanyPostSliderSection from '~/views/Company/components/CompanyPostSliderSection'

import * as containerStyles from './NotFound.module.scss'

const NotFound = () => (
  <MainLayout>
    <div className={containerStyles.notFoundPage}>
      <div className="container container-md">
        <div className="row align-items-center">
          <div className="col-12 col-md-7">
            <div className={containerStyles.notFoundPage__wrapper}>
              <h1 className={containerStyles.notFoundPage__title}>404</h1>
              <p className={containerStyles.notFoundPage__descr}>
                Oops, this page doesn’t exist.
              </p>
              <p className={containerStyles.notFoundPage__descr}>
                Just in case: do you want to build a web product? We can help!
              </p>
              <Link
                to="/case-studies/"
                className={containerStyles.notFoundPage__btn}
              >
                Check our latest works
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <StaticImage
              src="../../assets/images/not-found/not-found.png"
              alt="Not Found page at Codica"
              title="Oops, page not found"
              width={545}
              placeholder="blurred"
              className={containerStyles.notFoundPage__image}
            />
          </div>
        </div>
      </div>
      <div className={containerStyles.notFoundPage__recentPosts}>
        <CompanyPostSliderSection />
      </div>
      <SocialBlock />
    </div>
  </MainLayout>
)

export default NotFound
