import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllArticlePageProps } from '~/types/common-props'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import NotFound from '~/views/NotFound/NotFound.view'

const NotFoundPage = () => <NotFound />

export default NotFoundPage

export const pageQuery = graphql`
  query {
    strapiPage(page_name: { eq: "404" }) {
      ...MetaTagInformationPages
    }
  }
`

export const Head = ({ data }: AllArticlePageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/404/"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />
  )
}
